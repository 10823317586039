.bg-primary {
  background: #f24b13 !important;
}

.bg-white {
  background: #fff !important;
}

.bg-black {
  background: #000 !important;
}
