.drawer-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0.5rem;

  .content {
    max-height: 100%;
    padding: 0.5rem;
    overflow: auto;
  }

  .footer {
    width: 100%;
    text-align: center;
  }

  .content::-webkit-scrollbar {
    width: 0.5rem;
  }

  /* Track */
  .content::-webkit-scrollbar-track {
    border-radius: 0.69vw;
    background: #ececec;
  }

  /* Handle */
  .content::-webkit-scrollbar-thumb {
    border-radius: 0.69vw;
    background: #f24b13;
  }
}
