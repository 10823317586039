.input-number-format {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-width: 0;
  margin: 0;
  padding: 4px 11px;
  transition: all 0.3s;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #fff;
  background-image: none;
  color: rgb(0 0 0 / 85%);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}

.input-number-format:hover {
  border-right-width: 1px !important;
  border-color: #f24b13;
}

.input-number-format:focus {
  border-right-width: 1px !important;
  border-color: #f24b13;
  outline: 0;
  box-shadow: 0 0 0 2px rgb(242 75 19 / 20%);
}

.input-number-format[type='text'],
.input-number-format[type='password'],
.input-number-format[type='tel'] {
  appearance: none;
}

.input-select-brand {
  width: 10rem !important;
}
