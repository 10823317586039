.m-a {
  margin: auto !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.5rem !important;
}

.m-2 {
  margin: 1rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.m-4 {
  margin: 2rem !important;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.mt-2 {
  margin-top: 1rem !important;
}

.mt-3 {
  margin-top: 1.5rem !important;
}

.mt-4 {
  margin-top: 2rem !important;
}

.ml-1 {
  margin-left: 0.5rem !important;
}

.ml-2 {
  margin-left: 1rem !important;
}

.ml-3 {
  margin-left: 1.5rem !important;
}

.ml-4 {
  margin-left: 2rem !important;
}

.mb-1 {
  margin-bottom: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 1rem !important;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 2rem !important;
}

.mr-1 {
  margin-right: 0.5rem !important;
}

.mr-2 {
  margin-right: 1rem !important;
}

.mr-3 {
  margin-right: 1.5rem !important;
}

.mr-4 {
  margin-right: 2rem !important;
}
