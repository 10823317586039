.border {
  border: 0.1em solid #d5d5d7 !important;
}

.border-left {
  border-left: 0.1em solid #d5d5d7 !important;
}

.border-right {
  border-right: 0.1em solid #d5d5d7 !important;
}

.border-top {
  border-top: 0.1em solid #d5d5d7 !important;
}

.border-bottom {
  border-bottom: 0.1em solid #d5d5d7 !important;
}

.border-none {
  border: none !important;
}
