.side-bar {
  padding: 0.69vw 0;
  background: #f5f5f5;
  box-shadow: inset 0.063rem 0 0 #e2e2ea;
  font-family: sans-serif;
}

.side-bar-icon {
  position: absolute;
  top: 0.875rem;
  right: -0.25rem;
  font-size: 1.25rem;
}

.side-bar-nav {
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
}

.side-bar ::-webkit-scrollbar {
  width: 0.5rem;
}

/* Track */
.side-bar ::-webkit-scrollbar-track {
  border-radius: 0.69vw;
  box-shadow: inset 0 0 0.34vw grey;
}

/* Handle */
.side-bar ::-webkit-scrollbar-thumb {
  border-radius: 0.69vw;
  background: #8c8c8c;
}

.side-header-menu {
  margin-bottom: 0.69vw;
  padding: 0.34vw 0.97vw;
  color: #8c8c8c;
}

.menu-items {
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;
  border: none !important;
  background-color: #f5f5f5;
}

.left-menu-scroll {
  height: calc(100vh - 10.4rem) !important;
}

.ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-menu-item-selected::after {
  border: none !important;
}

.ant-menu-sub {
  background-color: inherit !important;
}

.side-footer {
  margin: 1.04vw;
}

.mobile-nav {
  display: none;
}

.drawer,
.ant-drawer-body {
  padding: 0 0.3rem 0 0;
}

.menu-button {
  position: fixed;
  z-index: 1000;
  right: 0.625rem;
  bottom: 0.625rem;
}

@media (max-width: 768px) {
  .side-footer {
    display: none;
  }

  .left-menu-scroll {
    height: calc(100vh) !important;
  }

  .side-header-menu {
    padding-left: 1.875rem;
  }
}
