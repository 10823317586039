@import 'src/styles/alignment.css';
@import 'styles/auth.css';
@import 'src/styles/background.css';
@import 'src/styles/border.css';
@import 'src/styles/dashboard.css';
@import 'src/styles/drawer.scss';
@import 'src/styles/height.css';
@import 'src/styles/input.css';
@import 'src/styles/layout.css';
@import 'src/styles/margin.css';
@import 'src/styles/orders.css';
@import 'src/styles/padding.css';
@import 'src/styles/width.css';

@media (min-width: 768px) {
  ::-webkit-scrollbar {
    width: 0.7vw;
    height: 0.7vw;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #fff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #bebebe;
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #909090;
  }
}

body,
.app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

/* styles from old react app */
.content-sm {
  width: 300px;
}

@media screen and (max-width: 480px) {
  .content-sm {
    width: 100% !important;
  }
}

.card-block {
  padding-bottom: 50px;
}

.card-block .heading {
  margin-bottom: 20px;
}

.card-block .heading .title {
  margin-bottom: 0;
}

.card-block .heading .extra {
  margin-bottom: 0;
  float: right;
}

.heading-line {
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #aaa;
}

.card-header {
  display: flex;
  justify-content: space-between;
}

.heading-line::before {
  content: '';
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 75px;
  height: 2px;
  background: #f22613;
}

.card-channel-btn {
  display: inline-flex !important;
  position: relative;
  box-sizing: border-box;
  align-items: center !important;
  justify-content: center !important;
  height: 70px;
  margin: 0;
  padding: 0;
  border-radius: 2px;
  background: #fff;
  box-shadow: 1px 1px 5px #aaa;
  color: rgb(0 0 0 / 75%);
  font-size: 12px;
  font-size: 1.5em;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}

.ant-card-shadow-less {
  box-shadow: none;
}

.row-dragging {
  border: 1px solid #ccc;
  background: #fafafa;
}

.row-dragging td {
  padding: 5px 20px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.ant-menu-submenu-title > svg {
  margin-right: 1rem !important;
}

.ant-menu-item > svg {
  margin-right: 1rem !important;
}

.delete-icon {
  border: 0 !important;
  background-color: transparent !important;
}

.delete {
  fill: #646464;
}

.delete:hover {
  fill: #f22613 !important;
}

.slotting-actions-btns {
  display: flex;
  justify-content: space-around;
}

.delete-action {
  border: 0 !important;
  background-color: transparent !important;
  cursor: pointer;
}

.locations-action {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.locations-action > :not([hidden]) ~ :not([hidden]) {
  --space-x-reverse: 0;

  margin-right: calc(1rem * var(--space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--space-x-reverse)));
}
