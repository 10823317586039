.site-header {
  border-bottom: 0.1em solid #d5d5d7 !important;
}

.site-layout {
  height: 100vh;
}

.site-layout-container {
  max-height: 100vh;
  overflow: auto;
}

.site-layout-content {
  padding: 1rem;
}

.site-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
