.justify-start {
  justify-content: start !important;
}

.justify-end {
  justify-content: end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-left {
  justify-content: left !important;
}

.justify-right {
  justify-content: right !important;
}

.text-start {
  text-align: start !important;
}

.text-end {
  text-align: end !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}
