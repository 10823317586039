.auth-container {
  width: 100vw;
  height: 100vh;
  padding: 5rem;
}

.auth-content {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0 0.5rem 1rem #f24b13;
}

.img-section {
  width: 60%;
}

.form-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  padding: 1rem;
}

.auth-landing-img {
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  padding: 0.5rem;
}

@media (max-width: 768px) {
  .auth-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 2rem;
  }

  .auth-content {
    height: auto;
  }

  .img-section {
    display: none;
  }

  .form-section {
    width: 100%;
  }
}
