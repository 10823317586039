.order-blacklisted {
  background-color: #f9c4c4ff !important;
}

.order-duplicate {
  background-color: #fff44f7f !important;
}

.order-pickup {
  background-color: #19b5fe7f !important;
}

.order-img-modal {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.order-img-modal .ant-modal-content {
  width: 100%;
  height: 100%;
}

.order-img-modal .ant-modal-content .ant-modal-body {
  width: 100%;
  height: 100%;
}

.order-img-container {
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 2rem 0 !important;
}

.order-img {
  width: 28.5em;
  height: 100%;
}

.order-img-icon {
  width: 2em;
  height: 2em;
  border: #a9a9a9 0.1rem solid;
  box-shadow: #808080 0 0.1em 1em;
}

.order-item-qty-edited {
  padding: 0.5rem 0 !important;
  background: #ffff0140 !important;
}

.order-item-qty-zero {
  padding: 0.5rem 0 !important;
  background: #ff000026 !important;
}

.order-tabs .ant-tabs-nav .ant-tabs-nav-wrap div .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 1.5rem;
}

.sku-availability-success {
  box-shadow: 0 0.2rem 0.5rem #42ba96;
}

.sku-availability-error {
  box-shadow: 0 0.2rem 0.5rem #f33;
}

.buttons-container {
  display: flex;
  justify-content: flex-end;
}

.buttons-container,
.text-center > :not([hidden]) ~ :not([hidden]) {
  --space-x-reverse: 0;

  margin-right: calc(1rem * var(--space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--space-x-reverse)));
}

.all-tags {
  display: flex;
  align-items: center;
}

.inner-tags {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 1.8rem;
  cursor: pointer;
}

.api-tags {
  display: inline-flex !important;
  align-items: center;
  height: 1.8rem !important;
}

.all-tags-container,
.order-tags-container > :not([hidden]) ~ :not([hidden]) {
  --space-y-reverse: 0;

  margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(1rem * var(--space-y-reverse));
}

.tags-icon {
  display: flex !important;
  align-content: center !important;
}

.order-tags-buttons {
  display: flex !important;
  align-items: center;
}

.orders-search-bar {
  width: 30rem;
}
