.w-auto {
  width: auto !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-75 {
  width: 75% !important;
}

.w-70 {
  width: 70% !important;
}

.w-100 {
  width: 100% !important;
}
