.order-aging-tr {
  height: 95px;
}

.container-chart .inner {
  padding: 12px 12px 0;
  overflow: auto;
  box-shadow: 1px 1px 5px #aaa;
}

.container-chart .inner .chart-wrapper {
  height: 370px;
}

.container-chart .inner .chart-wrapper.chart-with-stats {
  height: 320px;
}

@media screen and (max-width: 480px) {
  .container-chart {
    padding: 2px;
    overflow: auto;
  }

  .inner {
    min-width: 1000px;
  }
}

@media screen and (min-width: 768px) {
  .container-chart .inner {
    height: 420px !important;
  }
}

.btn-styles-none {
  border: none !important;
  outline: none !important;
  background-color: inherit !important;
  text-align: left;
}

.font-weight-bold {
  font-weight: bold;
}

.font-sm {
  font-size: 0.85em;
}
